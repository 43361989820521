<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="itemTable"
          title="부품별 일상점검항목"
          :columns="grid1.columns"
          :data="grid1.data"
          :merge="grid1.merge"
          :gridHeight="grid1.height"
          :filtering="false"
          selection="multiple"
          :usePaging="false"
          :editable="editable"
          :isExcelDown="false"
          :columnSetting="false"
          :expandAll="true"
          rowKey="checkItemId"
          :hideBottom="true"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'checkItemPartName'">
              {{ props.row.checkItemPartName }}
              <span>
                <div v-if="editable">
                  <q-btn-group outline class="ColumInnerBtnGroup">
                    <template v-for="(btn, idx) in col.btns">
                      <q-btn
                        :key="idx"
                        :label="btn.label"
                        :icon="btn.icon ? btn.icon : void 0"
                        :color="btn.color ? btn.color : 'blue-grey-4'"
                        :text-color="btn.textColor ? btn.textColor : 'white'"
                        class="ColumInnerBtn"
                        align="center"
                        @click.stop="innerBtnClicked(col, props, btn)">
                        <q-tooltip v-if="btn.tooltip">
                          <span v-html="btn.tooltip" />
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-btn-group>
                </div>
              </span>
            </template>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="설비유형별 점검항목 전체초기화" icon="autorenew" :showLoading="false" @btnClicked="reNewData" />
              <c-btn v-if="editable && updateMode" label="부품추가" icon="add" :showLoading="false" @btnClicked="addrow" />
              <c-btn v-if="editable && updateMode && grid1.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove"/>
              <c-btn
                v-if="editable && updateMode && grid1.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="grid1.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveClass"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
        </c-table> 
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="itemTable2"
          title="부품별 예방점검항목"
          :columns="grid2.columns"
          :data="grid2.data"
          :merge="grid2.merge"
          :gridHeight="grid2.height"
          :filtering="false"
          selection="multiple"
          :usePaging="false"
          :editable="editable"
          :isExcelDown="false"
          :columnSetting="false"
          :expandAll="true"
          rowKey="checkItemId"
          :hideBottom="true"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'checkItemPartName'">
              {{ props.row.checkItemPartName }}
              <span>
                <div v-if="editable">
                  <q-btn-group outline class="ColumInnerBtnGroup">
                    <template v-for="(btn, idx) in col.btns">
                      <q-btn
                        :key="idx"
                        :label="btn.label"
                        :icon="btn.icon ? btn.icon : void 0"
                        :color="btn.color ? btn.color : 'blue-grey-4'"
                        :text-color="btn.textColor ? btn.textColor : 'white'"
                        class="ColumInnerBtn"
                        align="center"
                        @click.stop="innerBtnClicked(col, props, btn)">
                        <q-tooltip v-if="btn.tooltip">
                          <span v-html="btn.tooltip" />
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-btn-group>
                </div>
              </span>
            </template>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="설비유형별 점검항목 전체초기화" icon="autorenew" :showLoading="false" @btnClicked="reNewData2" />
              <c-btn v-if="editable && updateMode" label="부품추가" icon="add" :showLoading="false" @btnClicked="addrow2" />
              <c-btn v-if="editable && updateMode && grid2.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove2"/>
              <c-btn
                v-if="editable && updateMode && grid2.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave2"
                :param="grid2.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveClass2"
                @btnCallback="saveCallback2" />
            </q-btn-group>
          </template>
        </c-table> 
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">부품명</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMstCd" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelMst" />
          <q-btn flat label="추가" @click="setMst" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="prompt2" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">부품명</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMstCd2" autofocus @keyup.enter="setMst2" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelMst2" />
          <q-btn flat label="추가" @click="setMst2" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default 
  {
  name: 'equipment-item',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentTypeCd: '',
        equipmentName: '',
      }),
    },
    editable: {
      type: Boolean,
      default: true,
    },
    revEquipmentCd: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      prompt: false,
      hiddenMstCd: '',
      prompt2: false,
      hiddenMstCd2: '',
      grid1: {
        columns: [],
        height: '400px',
        data: [],
      },
      grid2: {
        columns: [],
        height: '400px',
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        dailyFlag: 'Y'
      },
      equipClassInsItemData: {
        equipmentType: '',
        equipmentCd: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
      itemDetailUrl: '',
      deleteUrl: '',
      saveUrl: '',
      updateUrl: '',
      checkUrl: '',
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      checkMethodItems: [],
      isSave: false,
      isSave2: false,
      isDelete: false,
      isDelete2: false,
      updateMode: false,
      saveType: 'POST',
      removeMode: false,
      rowCheckItemName: '',
    };
  },
  watch: {
    'revEquipmentCd'() {
      this.rowClick();
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // this.editable = this.$route.meta.editable;
      this.itemDetailUrl = selectConfig.mdm.equipment.class.item.equip.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.class.item.equip.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.class.item.equip.delete.url;

      this.$comm.getComboItems('MDM_CHECK_CYCLE_CD').then(_result => {
        this.$comm.getComboItems('MDM_METHOD_CHECK_CD').then(_result2 => {
          this.grid1.columns = [
            {
              required: true,
              name: 'checkItemPartName',
              field: 'checkItemPartName',
              label: '부품',
              btns: [
                { label: '', icon: 'add', color: 'orange', tooltip: '부품별 점검항목 추가' },
              ],
              type: 'custom',
              style: 'width:150px',
              align: 'left',
              sortable: false,
            },
            {
              required: true,
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목',
              align: 'left',
              type: 'text',
              style: 'width:180px',
              sortable: false,
            },
            {
              name: 'checkItemMethod',
              field: 'checkItemMethod',
              label: '점검방법',
              style: 'width:180px',
              type: 'text',
              align: 'left',
              sortable: false,
            },
            {
              name: 'checkStandard',
              field: 'checkStandard',
              label: '점검기준',
              style: 'width:180px',
              type: 'text',
              align: 'left',
              sortable: false,
            },
            {
              required: true,
              name: 'checkItemCycleCd',
              field: 'checkItemCycleCd',
              label: '주기',
              align: 'center',
              style: 'width:90px',
              type: 'select',
              comboItems: _result,
              sortable: false,
            },  
            {
              name: 'cbmTypeCd',
              field: 'cbmTypeCd',
              label: 'CBM<br>종류',
              align: 'center',
              style: 'width:80px',
              type: 'select',
              codeGroupCd: 'CBM_TYPE_CD',
              sortable: false
            },
            {
              name: 'gaugeWarnLcl',
              field: 'gaugeWarnLcl',
              label: '경고하한값',
              align: 'left',
              type: 'text',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'gaugeWarnUcl',
              field: 'gaugeWarnUcl',
              label: '경고상한값',
              align: 'left',
              type: 'text',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'gaugeExecLcl',
              field: 'gaugeExecLcl',
              label: '실행하한값',
              align: 'left',
              type: 'text',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'gaugeExecUcl',
              field: 'gaugeExecUcl',
              label: '실행상한값',
              align: 'left',
              type: 'text',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'cbmFlag',
              field: 'cbmFlag',
              label: 'CBM<br>여부',
              align: 'center',
              style: 'width:50px',
              type: 'check',
              true: 'Y',
              false: 'N',
              sortable: false,
            },
          ]
          this.grid1.merge =  [
            { index: 0, colName: "checkItemPartName" },
          ]
          this.grid2.columns = [
            {
              required: true,
              name: 'checkItemPartName',
              field: 'checkItemPartName',
              label: '부품',
              btns: [
                { label: '', icon: 'add', color: 'orange', tooltip: '부품별 점검항목 추가' },
              ],
              type: 'custom',
              style: 'width:150px',
              align: 'left',
              sortable: false,
            },
            {
              required: true,
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목 내용',
              align: 'left',
              type: 'text',
              style: 'width:180px',
              sortable: false,
            },
            {
              name: 'checkMethodCd',
              field: 'checkMethodCd',
              required: true,
              label: '검사방법',
              align: 'center',
              style: 'width:110px',
              type: 'select',
              comboItems: _result2,
              sortable: false,
            },
            {
              name: 'decisionBasis',
              field: 'decisionBasis',
              label: '판정기준',
              align: 'left',
              type: 'text',
              style: 'width:200px',
              sortable: false,
            },
            {
              name: 'checkCaution',
              field: 'checkCaution',
              label: '점검시 안전사항',
              align: 'left',
              type: 'text',
              style: 'width:200px',
              sortable: false,
            },
            {
              name: 'cbmTypeCd',
              field: 'cbmTypeCd',
              label: 'CBM<br>종류',
              align: 'center',
              style: 'width:80px',
              type: 'select',
              codeGroupCd: 'CBM_TYPE_CD',
              sortable: false
            },
            {
              name: 'gaugeWarnLcl',
              field: 'gaugeWarnLcl',
              label: '경고하한값',
              align: 'left',
              type: 'text',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'gaugeWarnUcl',
              field: 'gaugeWarnUcl',
              label: '경고상한값',
              align: 'left',
              type: 'text',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'gaugeExecLcl',
              field: 'gaugeExecLcl',
              label: '실행하한값',
              align: 'left',
              type: 'text',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'gaugeExecUcl',
              field: 'gaugeExecUcl',
              label: '실행상한값',
              align: 'left',
              type: 'text',
              style: 'width:85px',
              sortable: false,
            },
            {
              name: 'cbmFlag',
              field: 'cbmFlag',
              label: 'CBM<br>여부',
              align: 'center',
              style: 'width:50px',
              type: 'check',
              true: 'Y',
              false: 'N',
              sortable: false,
            },
          ]
          this.grid2.merge =  [
            { index: 0, colName: "checkItemPartName" },
          ]
          this.rowClick();
        })
      })
    },
    rowClick() {
      this.updateMode = true;
      // 설비유형별 점검항목 조회      
      this.$http.param = {checkItemPartCd: 'CIPC000001', equipmentCd: this.revEquipmentCd === this.param.equipmentCd ? this.param.equipmentCd : this.revEquipmentCd}; // 설비부품별 일상점검
      this.$http.url = this.itemDetailUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid1.data = this.$_.clone(_result.data);
      },);
      // 설비유형별 점검항목 조회      
      this.$http.param = {checkItemPartCd: 'CIPC000002', equipmentCd: this.revEquipmentCd === this.param.equipmentCd ? this.param.equipmentCd : this.revEquipmentCd}; // 설비부품별 예방점검
      this.$http.url = this.itemDetailUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid2.data = this.$_.clone(_result.data);
      },);
    },
    addrow() {
      this.prompt = true;
    },
    remove() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid1.data = this.$_.reject(this.grid1.data, { checkItemId: item.checkItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass() {
      if (this.$comm.validTable(this.grid1.columns, this.grid1.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick()
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      this.grid1.data.splice(props.rowIndex+1, 0, {
        equipmentCd: this.param.equipmentCd,
        plantCd: this.param.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000001', // 설비부품별 점검
        checkItemPartName: props.row.checkItemPartName,
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        cbmTypeCd: null,
        cbmFlag: 'N',
        sortOrder: Number(props.row.sortOrder + 1),
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    cancelMst() {
      this.hiddenMstCd = '';
      this.prompt = false;
    },
    setMst() {
      this.prompt = false;
      this.grid1.data.push({
        equipmentCd: this.param.equipmentCd,
        plantCd: this.param.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000001', // 설비부품별 점검
        checkItemPartName: this.hiddenMstCd,
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        cbmTypeCd: null,
        cbmFlag: 'N',
        sortOrder: this.grid1.data.length == 0 ? 1 : (this.grid1.data.length + 1) * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
      this.hiddenMstCd = '';
    },
    reNewData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '현재 부품별 일상점검항목을 삭제하고\r\n설비유형별 일상점검항목 으로\r\n전체 초기화 하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = transactionConfig.mdm.equipment.class.item.equip.reset.daily.url;
          this.$http.type = 'PUT';
          this.$http.param = {
            equipmentCd: this.param.equipmentCd,
            equipmentTypeCd: this.param.equipmentTypeCd,
            regUserId: this.$store.getters.user.userId,
          };
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '초기화 되었습니다.', /* 저장되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.rowClick();
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    
    addrow2() {
      this.prompt2 = true;
    },
    remove2() {
      let selectData = this.$refs['itemTable2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid2.data = this.$_.reject(this.grid2.data, { checkItemId: item.checkItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass2() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick()
    },
    datachange2(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked2(col, props) {
      this.grid2.data.splice(props.rowIndex+1, 0, {
        equipmentCd: this.param.equipmentCd,
        plantCd: this.param.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000002', // 설비부품별 점검
        checkItemPartName: props.row.checkItemPartName,
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
        cbmTypeCd: null,
        cbmFlag: 'N',
        sortOrder: Number(props.row.sortOrder + 1),
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    cancelMst2() {
      this.hiddenMstCd2 = '';
      this.prompt2 = false;
    },
    setMst2() {
      this.prompt2 = false;
      this.grid2.data.push({
        equipmentCd: this.param.equipmentCd,
        plantCd: this.param.plantCd,
        checkItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000002', // 설비부품별 점검
        checkItemPartName: this.hiddenMstCd2,
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
        cbmTypeCd: null,
        cbmFlag: 'N',
        sortOrder: this.grid2.data.length == 0 ? 1 : (this.grid2.data.length + 1) * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
      this.hiddenMstCd2 = '';
    },
    reNewData2() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '현재 부품별 예방점검항목을 삭제하고\r\n설비유형별 예방점검항목 으로\r\n전체 초기화 하시겠습니까?',
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = transactionConfig.mdm.equipment.class.item.equip.reset.deterior.url;
          this.$http.type = 'PUT';
          this.$http.param = {
            equipmentCd: this.param.equipmentCd,
            equipmentTypeCd: this.param.equipmentTypeCd,
            regUserId: this.$store.getters.user.userId,
          };
          this.$http.request(() => {
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '초기화 되었습니다.', /* 저장되었습니다. */
              type: 'success', // success / info / warning / error
            });
            this.rowClick();
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>
